.weatherAlert{
    display: flex;
    justify-content: center;
    text-align: center;
    color: white;
    margin: 0;
    
}

.headline{
    font-weight: bolder;
}

.w-info{
    padding: .5em;
    display: flex;
    flex-flow: column;
    width: 70%;
    background-color: red;
    opacity: 75%;
    border-radius: 25px;
    filter: drop-shadow(.1em .1em .25em grey );
    backdrop-filter: blur(0);
}

.w-info:empty{
    background-color: aquamarine;
}
.headline{

    display: flex;
    justify-content: center;
    align-items: center;
}

.headline p{
    width:100%
}
.headline button{
    margin-left: auto;
    display: flex;
    justify-content: right;
    align-items: center;
    cursor:pointer;
    background: transparent;
    border: none;
    outline: none;
    color:grey;
    height: auto;
}



.more-details a{
    margin-top:.5em;
    display: flex;
    justify-content: center;
    color: white;
    font-size: 1em;
    font-weight: bolder;
    padding:.5em;
}

.more-details a:hover{
    color:grey
}


@media only screen and (max-width: 1024px){

    .weatherAlert{
        text-align: center;
        
    }
    .w-info{
        margin-top:1em;
        display: flex;
        flex-flow: column;
        width: 90%;
        background-color: red;
        opacity: 75%;
        border-radius: 25px;
        font-size: .75em;
       
    }

}
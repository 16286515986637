@import url("https://fonts.googleapis.com/css2?family=Armata&display=swap");

*{
    box-sizing: border-box;
}

header{
    display: flex;
    max-width:100%;
    min-height: 20%;
    position:absolute;
}

header .nav-btn{
    visibility: none;
    opacity: 0;
}

header nav{
    font-family: 'Armata';
    position: absolute;
    top:40%;
    min-height: 20%;
    max-height: 20%;
    min-width: 100%;
    display: flex;
    float: center;
    align-items: center;
    justify-content: center;
    font-size: .8em;
}

a{
    display: flex;
    margin-right: 2%;
    font-size: 2em;
}

a:link, a:visited{

    text-decoration: none;
    color: black;
}

.links a:hover{
    background-color: #FF7A00;
    padding:5px;
}

.nav-logo {
    margin-top: 2%;
    margin-left:0.5%;
    width:8%;
    filter: drop-shadow(.1em .1em .1em grey );
     
 }
 
 .rect1{
     display:flex;
     width: 9.75%;
     height: 15%;
     background-color: #FF7A00;
     position: absolute;
     top: 0;
     margin-top:0.5%;
     margin-bottom:0.5%;
     
   
    
 }
 
 .rect2{
     display:flex;
     width:10%;
     height: 15%;
     float:left;
     background-color: #FF7A00;
     position: absolute;
     top: 33.5%;
     left: 7.8%;
     margin-top:0.5%;
     margin-bottom:0.5%;
     transform:rotate(45deg);
    
 }
 
 .rect3{
     display:flex;
     min-width: 84.25%;
     height: 15%;
     background-color: #FF7A00;
     position: absolute;
     bottom: 13.4%;
     left:15.75%;
     margin-top:0.5%;
 }


@media only screen and (max-width: 1024px){
    
    header .nav-btn{
        margin-left: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor:pointer;
        background: transparent;
        border: none;
        outline: none;
        color:grey;
        margin-right: 1em;
        width: 10%;
        visibility: visible;
        opacity: 1;
    }

    header .nav-btn #burger,   header .nav-btn #burger#x{
        width: 75%;
        height:50%;

    }



    header nav{
        position: absolute;
		top: -200vh;
		min-height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
        align-items: center;
        justify-content: center;
		gap: 3%;
        background-color: #1B1E62;
        transition: all 0.3s ease-in;
        z-index: 9;
     
    }

    .links a{
        margin-left: 5%;
        width: 100%;
        justify-content: left;
       
    }
    .links a:link, a:visited{
        color:#FF7A00;
    }
    .links a:hover{
        color:white;
        background-color: #FF7A00;
        z-index: 4px;
    }
    .links{
    
        display:flex;
        width:100%;
        height:100%;
        float:center;
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom:0;
       
       
    }
    
    nav .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
	}

    nav .nav-bars {
        visibility: hidden;
        opacity: 0;
	}

    header .responsive_nav{
        transform: translateY(200vh);
    }
    header .responsive_nav{
        transform: translateY(200vh);
    }

   
    .rect1, .rect2, .rect3{
        visibility: none;
        opacity: 0;
    }
    
    .nav{
        background-color: #FF7A00;
        display: flex;
    }

    .nav-logo {
        display: inline-block;
        margin:0.5%;
        width:initial;
        min-width: 8%;
        max-width: 20%;
        line-height: normal;

    }
     


}


@media only screen and (max-width: 400px){
    .rect1, .rect2, .rect3{
        visibility: none;
        opacity: 0;
    }
    
    .nav{
        background-color: #FF7A00;
        display: flex;
    }

    .nav-logo {
        display: inline-block;
        margin:0.5%;
        width:initial;
        min-width: 8%;
        max-width: 20%;
        line-height: normal;

    }
     


}
@import url("https://fonts.googleapis.com/css2?family=Armata&display=swap");


img{
    filter: drop-shadow(.1em .1em .15em grey );
    backdrop-filter: blur(0);
}

.block1{

    display: flex;
    width: 100%;
    min-height: 50%;
    max-width: 100%;
    line-height: 20em;
    margin: 0 auto;
    align-items: center;
    border-radius: 25px;
    justify-content: center;
    margin-top: 2em;
    margin-bottom: 5em;

   
}
.home h1, .home h2{
    font-family: armata;
}
.title-stats{
    display: inline-block;
   
    margin: 0 auto;
    vertical-align: middle;
    line-height: normal;
    text-align: center;
    min-height: 20em;
    max-width: 100%;
    overflow: hidden;

}


.title-stats h2{
    font-size: 2em;
    font-family: Armata;
}

.title-stats h1{
    font-size: 3em;
    font-family: Armata;
    color: #1B1E62;
    font-weight: bolder;
}

.stats-table{
    margin: 0 auto;

}

.stats-table caption{
    font-family: Armata;

}

.stats-table th, tr, td{
    padding: 1em;
    margin: auto 0;
    
}

.stats-table th{
    color: #1B1E62;
    font-size: 1.20em;
}



.truck-img{
    display: inline-block;
    width:60%;
    margin: 0 auto;
    top:50%;
    vertical-align: middle;
    line-height: normal;
    text-align: center;
 
}

.truck{
    max-width: 50%;
    max-height: 100%;
    border-radius: 25px;
    border: 2px solid #1B1E62;
}



.block2{

    background-color: #E8E8EF;
    display: flex;
    width: 65%;
    max-width: 65%;
    line-height: 20em;
    margin: 0 auto;
    align-items: center;
    border-radius: 25px;
    justify-content: center;
    margin-top: 5em;
    margin-bottom: 5em;
    overflow:hidden;
    min-height:25em;
    filter: drop-shadow(.1em .1em .15em grey );
    backdrop-filter: blur(0);

   
}


.about-ems, .billing{
    display: inline-block;
    max-width:50%;
    line-height: normal;
    padding-left: 2em;
    max-height: 100%;
    position: relative;
    
}

.our_story h1, p, .billing p{
    max-height: 100%;
    max-width: 100%;
    font-size: 1em;
   
}

.about-ems h1{
    color: #1B1E62;
}


.about-ems-img{
    padding:1em;
    position: relative;
    display: inline-block;
    min-height: 100%;
    max-width:50%;
    margin: 0 auto;
    line-height: normal;
    
}



.about-img{
    max-width: 100%;
    min-height: 100%;
    padding: 1em;
    border-radius: 25px;
}


.block3{

    background-color: #E8E8EF;
    display: flex;
    width: 65%;
    max-width: 65%;
    line-height: 20em;
    margin: 0 auto;
    align-items: center;
    border-radius: 25px;
    justify-content: center;
    margin-top: 5em;
    margin-bottom: 5em;
    overflow: hidden;
    min-height:25em;
    filter: drop-shadow(.1em .1em .15em grey );
    backdrop-filter: blur(0);
   
}


.wmems{
    display: inline-block;
    max-width:50%;
    line-height: normal;
    padding-left: 2em;
    padding-right: 1em;
    max-height: 100%;
    position: relative;
    
}

.about-ems a{
    color:inherit;
    text-decoration: underline;
    font-size: inherit;
    width:max-content;  
    font-weight: 500;
    display:inline;
}

.about-ems a:hover{
    color: #FF7A00;
}

.wmems h1, p{
    max-height: 100%;
    max-width: 100%;
}

.wmems h1{
    color: #1B1E62;
}


.wmems-img{
    position: relative;
    display: inline-block;
    max-width:50%;
    max-height: 100%;
    line-height: normal;
    padding:1em;

}


.w-img{
    max-width: 100%;
    max-height: 100%;
    border-radius: 25px;
    padding: 1em;
}



.block4{

    background-color: #E8E8EF;
    display: flex;
    width: 65%;
    max-width: 65%;
    line-height: 20em;
    margin: 0 auto;
    align-items: center;
    border-radius: 25px;
    justify-content: center;
    margin-top: 5em;
    margin-bottom: 5em;
    overflow:hidden;
    min-height:25em;
    filter: drop-shadow(.1em .1em .15em grey );
    backdrop-filter: blur(0);

   
}

.block5{
    display: flex;
    text-align: center;
    margin: 0 auto;
    align-items: center;
 
    justify-content: center;
    margin-top: 5em;
    margin-bottom: 5em;
    overflow:hidden;
    font-size: 1em;
    color: #1B1E62;
    font-family: Armata;
    width:100%;
}

.block5 a{
    font-size: 1.25em;
    color: #1B1E62;
    font-family: Armata;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    
   
}

.block5 a:hover{
    color:#FF7A00;
}


@media only screen and (max-width: 1024px){

    .block1{
        margin-top: 1em;
        min-height:10em;
        max-height:50em;
        max-width: 85%;
        display: flex;
        flex-flow: column;
        line-height: 20em;
        align-items: center;
        justify-content: center;
        margin-bottom: 5em;

    }


    .block1 h1{
        font-size: 2.5em;
    }

    .block1 th, td{
        font-size: 1em;
        width:33%
        
    }


    .block2{

        min-height:10em;
        max-height:50em;
        min-width: 85%;
        background-color: #E8E8EF;
        display: flex;
        flex-flow: column;
        line-height: 20em;
        align-items: center;
        border-radius: 25px;
        justify-content: center;
        margin-bottom: 5em;

    }

    .about-ems, .billing{
        display: inline-block;
        max-width: 100%;
        line-height: normal;
        padding-left: 2em;
        padding-right: 2em;
        max-height: 50%;
        position: relative;
        
    }

    .about-ems p, .billing p{
        max-height: 100%;
        max-width: 100%;
        font-size: .75em;
    
    }

    .about-ems h1{
        color: #1B1E62;
    }


    .about-ems-img{
        padding:1em;
        position: relative;
        display: inline-block;
        min-height: 100%;
        max-width:85%;
        margin: 0 auto;
        line-height: normal;
        overflow: hidden;
        
    }



    .about-img{
        max-width: 100%;
        min-height: 100%;
        border-radius: 25px;
    }


    .block3{

        min-height:10em;
        max-height:50em;
        min-width: 85%;
        background-color: #E8E8EF;
        display: flex;
        flex-flow: column;
        line-height: 20em;
        align-items: center;
        border-radius: 25px;
        justify-content: center;
        margin-bottom: 5em;
    
    }


    .wmems{
        display: inline-block;
        max-width: 100%;
        line-height: normal;
        padding-left: 2em;
        padding-right: 2em;
        max-height: 50%;
        position: relative;
        order:1;
        
    }

    .wmems p{
        max-height: 100%;
        max-width: 100%;
        font-size: .75em;
    }

    .wmems h1{
        color: #1B1E62;
    }


    .wmems-img{
        order: 2;
        padding:1em;
        position: relative;
        display: flex;
        justify-content: center;
        min-height: 100%;
        max-width:90%;
        margin: 0 auto;
        overflow: hidden;
        border-radius: 25px;

    }

    .w-img{
        max-width: 100%;
        max-height: 100%;
        border-radius: 25px;
        padding: 1em;
    }

    .block4{

        min-height:10em;
        max-height:50em;
        min-width: 85%;
        background-color: #E8E8EF;
        display: flex;
        flex-flow: column;
        line-height: 20em;
        align-items: center;
        border-radius: 25px;
        justify-content: center;
        margin-bottom: 5em;

    }

}

@media only screen and (max-width: 700px){
    .block5 a{
        font-size: 1em;
    }
}


@media only screen and (max-width: 500px){
    .block5 a{
        font-size: .75em;
        padding:1em;
    }
}
@media only screen and (max-width: 400px){

    .block1 th, td{
        font-size: .75em;
    }    
}




.wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
}

.accordion {
    display: inline-block;  
    border-radius: 15px;
    background-color: #E8E8EF;
    width:80%;
    height:max-content;
    max-height: 100%;
    filter: drop-shadow(0em .1em .1em grey );
    backdrop-filter: blur(0);
}


.item{
    padding:.8em;
    cursor: pointer;
}


.title{
    color:#1B1E62;
    display:flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: 12px;

}

.content{
    color: black;
    font-size: .9em;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0,1,0,1);
}

.content.show{
    height: auto;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(1,0,1,0);
}

@media only screen and (max-width: 1024px){

    .wrapper{
        height: max-content;
    }
    .item{
        padding-top:0;
        padding-bottom:.5em;
    }
    .content.show{
        max-height: 9999px;
    }
}


@media only screen and (max-width: 460px){
    .selected{
        padding-left:1em;
    }
}
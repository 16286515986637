.footer{
    margin: auto 0;
    text-align: center;
    min-height: 6em;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:1em;
    padding-bottom:0;
}

.foot-links{
    display: flex;
    padding: 1em;
    min-height: 6em;
    justify-content: center;
    align-items: center;
    font-size: .5rem;
  
}

ul{
    padding:0
}

.contact{
  display: flex;
  margin: 0;
  text-align: center;
  min-height: 6em; 
  padding: 1em;
  justify-content: center;
  align-items: center;


}
.contact h4{
   display: flex;  
   color: #1B1E62;
   margin:0;
   justify-content: center;
  
}

.contact p{
    margin:0;
  
}

@media only screen and (max-width: 1024px){
    .foot-links a:visited{
      color:black;
    }
    .foot-links a{
        font-size: 1.5em;
    }
    .footer{
        font-size: .75em;
    }
}

.container{

  margin: 0;
  min-height: 100%;
  min-width:100%;
 
}
  
.nav{
  position: relative;
  width:100%;
}


.container .foot{
  width:100%;
  bottom: 0;


}




.join-container{
    width: 100%;
    max-height: 100%;
    margin: 0 auto;

}

.join-title{
    display: inline-block;
    width: 100%;
}
h1{
    text-align: center;
    color:#1B1E62;

}

.join-content{
    display: flex;
    justify-content: center;
}

.join-info{
    display: flex;
    justify-content: center;
    align-items: center;
    width:40%;
}

.join-form{
    display: inline-block;
    width:30%;
    height:75%;
    margin-top: 1em;
    
}


@media only screen and (max-width: 1024px){
    .join-container{
        height:auto;
    }
 
    .join-content{
        flex-flow: column;
        height:auto;
    }
    .join-info{
        width: 100%;
    }

    .join-form{
        width: 100%;
    }
    

}

.officers-pg{
   
   min-width:100%;
   display: flex;
   flex-flow:column;
   justify-content: center;
   align-items: center;
   
}


.off-title{
    font-family: armata;
}

.officer-table th{
    max-width:33%;
    text-align: left;
    padding:1em;
    padding-left: 2em;
    font-family: Armata;
    color: #1B1E62;
    
}

.officer-table td{
    text-align: left;
    padding:1em;
    padding-left: 2em;
    max-width: 33%;

}


.officer-table a{
    font-size: 1em;

}


.officer-table {
    background-color: #E8E8EF;
    max-width: 100%;
    min-width: 100%;
    border-radius: 25px 25px 0px 0px;
    filter: drop-shadow(.1em .1em .25em grey );
    backdrop-filter: blur(0);


}

.officers img{
    min-width:100%;
    border-radius: 0px 0px 25px 25px;
    filter: drop-shadow(.1em .1em .25em grey );
    backdrop-filter: blur(0);

}

.officers{
    display: table;
    max-width:50%;
}

@media only screen and (max-width: 1024px){
    .officers-pg{
        font-size: 0.75em;
    }
    .officers{
        display:flex;
        justify-content: center;
        flex-flow:column;
        align-items: center;
        max-width: 90%;
        min-height: 100%;
    }

    .officer-table{
        max-width:100%;
        overflow: scroll;
    }

    .officers img{
        max-width: 90%;
    }

    .officer-table th{
        max-width:33%;
        text-align: left;
      
        padding: 1em;
        font-family: Armata;
        color: #1B1E62;
    }
    
    .officer-table td{
        text-align: left;
        padding-left: 0.5em;
   
    }

}

@media only screen and (max-width: 520px){

    .officers-pg{
        font-size: 0.70em;
    }
    .officers{
        min-width: 90%;
    }

    .officer-table{
        max-width:100%;
        overflow: scroll;
    }

    .officer-table th{
        min-width:33%;
        text-align: left;
        font-size: .65em;
        padding: 1em;
        font-family: Armata;
        color: #1B1E62;
    }
    
    .officer-table td{
        text-align: left;
        padding-left: 0.5em;
        font-size: .70em;    
    }
}
.form-container{
    display: flex;
    align-items: center;
    width:100%;
    height: 100%;
}


.form-container form{
    max-width: 100%;
}

.field h2{
    margin: auto;
}
.sameline h2{
    margin: auto;
}
.form-container label{
    display:flex;
    font-size: large;
    color:#1B1E62;
    
}


.form-container input{
    border-radius: 25px;
    border: none;
    padding: 15px;
    font-size: medium;
    min-height: 30px;
    min-width: 300px;
    background-color: #D9D9D9;
    filter: drop-shadow(.1em .1em .05em grey );
    backdrop-filter: blur(0);

}


.form-container button{
    display: flex;
    border-radius: 25px;
    background-color: #FF7A00;
    font-size: large;
    color:white;
    border: none;
    min-height: 30px;
    min-width: 100px;
    cursor: pointer;
    margin:20px auto;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(.1em .1em .05em grey );
    backdrop-filter: blur(0);
    
}


@media only screen and (max-width: 1024px){
    .form-container{
        justify-content: center;
        padding:2em;
    }

    .form-container label{
        font-size: .65em;
    }

    .form-container input{
        max-height: 2em;
        min-height: 1em;
    }





}
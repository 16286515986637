


.Donate{
    background-color: #E8E8EF;

    margin-bottom:3em;
    display: flex;
    align-items: center;
    justify-content: center;


}

h1{
    text-align: center;
    color: #1B1E62;
    font-family: Armata;
}


.contact-info{

    width: 35%;
    padding: 2em;
    margin:1em;

    display: flex;
    justify-content: center;
 
   
}

.or{
    width:0.5em;
    text-align: center;
    margin:3em;
}

.contact-info .Address{
    margin: auto 0;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
  
}

.contact-info .Address h3{
    text-align: center;
}

.contact-info .Address p{
    text-align: center;
    width:90%;
    line-height: 2;
    display: block;
}


.paypal{
    width: 35%;
    padding: 2em;
    margin:1em;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items:center ;

    
}

.img{
    
    min-height: 100%;
    min-width:100%;
    margin: 0;
    display: flex;
    flex-flow: column;



}

.img h3{

    text-align: center;
}

.img img{
    max-width: 50%;
    max-height:100%;
}

.img a{
    display: flex;
    justify-content: center;
    min-height:100%;

}


.donate-pg{
    display: flex;
    flex-flow:column;
    justify-content: center;
  
}


.donate-pg .contact-vp a{
  
    display: flex;
    justify-content: center;
    font-size: 1em;
}

.donate-pg a:hover{
    color: #FF7A00;
}

.donate-pg h3{
    text-align: center;
}

.contact-vp{
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
}



@media only screen and (max-width: 1024px){
    .donate-pg{
        max-width: 100%;
        min-height: 100%;
    }
    .Donate{
        max-width: 100%;
        display: flex;
        flex-flow: column;
        background-color: #E8E8EF;
        margin-bottom: 0;
    }

    .contact-info{

        width: 100%;
        margin-top: 0;
        font-size: 1.10em;
        padding-bottom: 0;
        padding-top: 0;
        margin-bottom: 0em;
     
    
        
    }


    .or{
        margin:0;
        width: 100%;
      
    }
    .paypal{
        width: 100%;
        padding-top: 0em;
        margin:0em;
        max-height: 50%;
        display: flex;
        justify-content: center;
        align-items:center ;
        margin-bottom: 0em;
        margin-top: 0;
     
      
    }
    .img{
        display: flex;
        justify-content: center;
        align-items: center;
        max-height:100%;
    }
    .img a{
        max-width:75%;
        max-height: 100%;
    }
    
    .contact-vp{
        display: flex;
        justify-content: center;
        font-size: .85em;
    }
    
}

@media only screen and (max-width: 600px){

    .img a{
        max-width: 100%;
    }

    .img img{
        max-width: 75%;
    }

    .or{
        margin-top: 0em;
  
    }

}

@media only screen and (max-width: 420px){
    .contact-info{
        font-size: 1em;
    }
    .Address{
        font-size: .90em;
    }
    .contact-vp{
        font-size: .75em;
    }

    .contact-vp{
        font-size: .65em;
    }
}


@media only screen and (max-width: 420px){
 
    .Address{
        font-size: .8em;
        width:100%;
    }
    .or{
        width:100%;
        text-align: center;
    }


}

@media only screen and (max-width: 315px){
    .Address{
        font-size: .75em;
    
    }
    .contact-vp{
        font-size: .5em;
    }
}

@media only screen and (max-width: 300px){
    .Address{
        font-size: .70em;
    
    }

}

@media only screen and (max-width: 280px){

    .Address{
        font-size: .60em;
    
    }

}